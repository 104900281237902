import { useEffect, useState } from "react";
import { ApiService as api } from "../../services/ApiService";

export const usePasswordConfirmPage = () => {
    const [confirmText, setConfirmText] = useState("Attendi...");
    const [password, setPassword] = useState("");
    const [busy, setBusy] = useState(false);
    const [sent, setSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        // checkEmail();
    }, []);

    const changePassword = async (evt) => {
        let toastMessage = "";
        setErrorMessage(toastMessage);
        setBusy(true);
        evt.preventDefault();

        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const userId = params["userId"];
        const code = params["code"];

        if (!Object.keys(params).length) {
            setErrorMessage("Assicurati di aver fatto click sul link dell'email che ti abbiamo inviato per il recupero della tua password.");
            setBusy(false);
            return;
        }

        try {
            const loginResponse = await api.RecoverPWDConfirm({
                userId,
                code,
                passwordToUpdate: password,
            });
            if (loginResponse.result) {
                setErrorMessage("");
                setBusy(false);
                setSent(true);
            } else {
                setErrorMessage(loginResponse.message || "Utente o codice non trovato.");
            }
            setBusy(false);
        } catch (error) {
            setErrorMessage("C'è stato un problema con la tua richiesta. Riprova per favore.");
            setBusy(false);
        }
    };

    return {
        confirmText,
        password,
        setPassword,
        changePassword,
        busy,
        sent,
        errorMessage,
    };
};
