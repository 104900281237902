import chevron from "../../assets/imgs/chevron-left.svg";
import loaderGif from "../../assets/imgs/loader.gif";
import BottomNavigation from "../../components/BottomNavigation";
import "moment/locale/it";
import Button from "../../components/Button";
import { usePrenotaPage } from "./usePrenotaPage";
import { useSwipeable } from "react-swipeable";
import "./style.css";

export const Prenota = () => {
  const {
    update,
    slots,
    selectedSlot,
    setSelectedSlot,
    selectedDate,
    setSelectedDate,
    currentMonthIdx,
    currentMonth,
    daysInCurrentMonth,
    prevMonth,
    nextMonth,
    goToPrenota,
  } = usePrenotaPage();

  const swipeHandlers = useSwipeable({
    onSwipedLeft: nextMonth,
    onSwipedRight: prevMonth,
  });

  return (
    <>
      <div id="prenota-page">
        <div className="bg--primary">
          <div id="book-now-header" className="box--3">
            <img
              onClick={prevMonth}
              className={currentMonthIdx == 0 ? "invisible" : ""}
              src={chevron}
              alt=""
            />
            <p className="text--uppercase">
              {currentMonth?.locale("it").format("MMMM")}
            </p>
            <img onClick={nextMonth} className="mirror" src={chevron} alt="" />
          </div>
        </div>
        <div className="box--3">
          <div className="divider--3"></div>

          <h1>prenota il tuo posto</h1>

          <div className="divider--2"></div>

          <div
            id="booking-calendar"
            className="text--center"
            {...swipeHandlers}
          >
            <p className="text--emphasis-2 fg--lightGray text--bold">LU</p>
            <p className="text--emphasis-2 fg--lightGray text--bold">MA</p>
            <p className="text--emphasis-2 fg--lightGray text--bold">ME</p>
            <p className="text--emphasis-2 fg--lightGray text--bold">GI</p>
            <p className="text--emphasis-2 fg--lightGray text--bold">VE</p>
            <p className="text--emphasis-2 fg--lightGray text--bold">SA</p>
            <p className="text--emphasis-2 fg--lightGray text--bold">DO</p>

            {!daysInCurrentMonth.length && (
              <div
                id="calendar-loader"
                className="text--center calendar-day-group"
              >
                <img src={loaderGif} alt="" />
              </div>
            )}
            {daysInCurrentMonth.map((itm, idx) => {
              return (
                <div
                  onClick={() => {
                    if (itm.disabled) return;
                    setSelectedDate(itm);
                    update("selectedDate", itm);
                  }}
                  key={`calendar-day-${Math.random()}`}
                  className={`calendar-day-group ${
                    itm && itm.disabled ? "disabled" : ""
                  } ${itm && itm.orders.length ? "busy" : ""} ${
                    itm &&
                    selectedDate &&
                    itm.dateObj.toISOString() ==
                      selectedDate.dateObj.toISOString()
                      ? "active"
                      : ""
                  }`}
                >
                  <div className="calendar-day-group__circle">
                    <p className="calendar-day-group__number">
                      {itm && itm.dateObj.format("D")}
                    </p>
                    <div className="calendar-day-group__dot"></div>
                  </div>
                </div>
              );
            })}
          </div>

          <div id="select-slot" className="select-slot">
            <div className="divider--3"></div>
            <p className="text--center text--bold">SCEGLI LO SLOT ORARIO</p>

            <div className="divider--2"></div>
            <div className="flex-cnt wrap--2 gap--1 no-stretch scelta-slot">
              {slots.map((itm, idx) => {
                return (
                  <button
                    key={`calendar-slot-${Math.random()}`}
                    onClick={() => {
                      setSelectedSlot(itm);
                      update("selectedSlot", itm);
                    }}
                    className={`button scelta-slot-btn flex-col--1 ${
                      selectedSlot.id == itm.id ? "" : "inverse"
                    }`}
                  >
                    {itm.text}
                  </button>
                );
              })}
            </div>
          </div>

          <div className="divider--1"></div>

          <Button
            text="prenota"
            disabled={!selectedDate || selectedDate?.orders.length}
            onClick={goToPrenota}
          />
        </div>
      </div>

      <div className="divider--4"></div>

      <BottomNavigation />
    </>
  );
};
