export const SessionService = () => {};

SessionService.snapshot = {
  userId: "",
  email: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  targhe: [],
  userBookings: [],
  selectedSlot: null,
  selectedDate: null,
  maps: {}
};

SessionService.set = (key, value) => {
  SessionService.snapshot[key] = value;
};

SessionService.merge = (object) => {
  SessionService.snapshot = { ...SessionService.snapshot, ...object };
};

SessionService.get = (key) => {
  if (!key) return SessionService.snapshot;
  return SessionService.snapshot[key];
};
