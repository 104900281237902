import { useSession } from "./useSession";
import { useEffect } from "react";
import { loadDomainCss } from "../services/env";

export const useDomainCss = () => { 
  const { read } = useSession();

  useEffect(() => {
    loadDomainCss(read("email"));
  }, []);

  return {};
};
