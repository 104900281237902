import React from "react";
import BottomNavigation from "../../components/BottomNavigation";
import MainHeader from "../../components/MainHeader";
import MainFooter from "../../components/MainFooter";
import Button from "../../components/Button";
import Toast from "../../components/Toast";
import { useAccountPage } from "./useAccountPage";

export const Account = () => {
  const {
    updateProfile,
    firstName,
    setName,
    lastName,
    setSurname,
    phoneNumber,
    setPhone,
    busy,
    errorMessage,
    logout,
  } = useAccountPage();

  return (
    <>
      <div id="account-page">
        <div className="box--3">
          <MainHeader />

          <h1>Il tuo Profilo</h1>
          <div className="divider--1"></div>
          <p className="align--center--text">
            Qui trovi le informazioni che hai inserito al momento della
            registrazione. <br />
            Puoi modificarle in qualsiasi momento, ricorda di salvare i dati
            quando hai finito!
          </p>

          <div className="divider--4"></div>

          <form onSubmit={updateProfile}>
            <div className="form-input-group">
              <label htmlFor="">Nome</label>
              <input
                type="text"
                autoComplete="new-password"
                value={firstName}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="divider--3"></div>
            <div className="form-input-group">
              <label htmlFor="">Cognome</label>
              <input
                type="text"
                autoComplete="new-password"
                value={lastName}
                onChange={(e) => setSurname(e.target.value)}
              />
            </div>
            <div className="divider--3"></div>
            <div className="form-input-group">
              <label htmlFor="">Numero cellulare</label>
              <input
                type="text"
                autoComplete="new-password"
                value={phoneNumber}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            <div className="divider--4"></div>

            <Button text="salva i dati" isLoading={busy} />

            {/* <div className="divider--8"></div> */}

            <div
              className="text--center fg--primary text--bold"
              onClick={logout}
            >
              ESCI
            </div>
            <div className="divider--2"></div>

            <hr />
            <div className="divider--4"></div>

            <MainFooter />
            <div className="divider--6"></div>
          </form>
        </div>
      </div>

      <div className="divider--5"></div>

      <BottomNavigation active="account" />

      <Toast open={errorMessage.length}>{errorMessage}</Toast>
    </>
  );
};
