import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ApiService as api } from "../../services/ApiService";
import { useSession } from "../../hooks/useSession";
// import { maps } from "../../assets/maps";

export const useDettaglioPrenotazioneMappaPage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { batchUpdate, read } = useSession();
    const { id, posto, data, oraInizio, oraFine, mapRef } = state;
    const [busy, setBusy] = useState(false);
    const [mapImage, setMapImage] = useState(null);
    const [mapLegend, setMapLegend] = useState(null);
    const [mapOverviewImage, setMapOverviewImage] = useState(null);
    const [zoom, setZoom] = useState(3);
    const [bottomSleeveOpen, setBottomSleeveOpen] = useState(false);

    const [maps, setMaps] = useState(read("maps"));

    const loadImage = async (imageName) => {
        setMapImage(maps[imageName]);
        setMapOverviewImage(maps[`${imageName}-overview`]);
        setMapLegend(maps[`${imageName} label`]);
    };

    useEffect(() => {
        loadImage(`${mapRef}`);
    }, []);

    useEffect(() => {
        if (null !== mapImage) {
            try {
                // const rect = document.querySelector(`[data-numerodelparcheggio="${posto.replaceAll(" ", "")}"]`);
                const rect = document.querySelector(`[id="park${posto.replaceAll(" ", "")}"] rect`);

                if (rect) rect.setAttribute("style", `${rect.getAttribute("style") ? rect.getAttribute("style") + "fill:yellow" : "fill:yellow"}`);
                centerParkingSlot();
            } catch (error) {
                console.log(error);
            }
        }
    }, [mapImage]);

    const centerParkingSlot = (smooth = true) => {
        // const rect = document.querySelector(`[data-numerodelparcheggio="${posto.replaceAll(" ", "")}"]`);
        const rect = document.querySelector(`[id="park${posto.replaceAll(" ", "")}"] rect`);
        if (rect) rect.scrollIntoView({ behavior: smooth ? "smooth" : "instant", block: "center", inline: "center" });
        // setTimeout(() => {
        //     const el = document.querySelector(`[data-numerodelparcheggio="1DPMS"]`);
        //     const parent = document.getElementById("map-cnt");
        //     const cy = el.getBoundingClientRect().top - ( parent.getBoundingClientRect().height - el.getBoundingClientRect().height ) / 2;
        //     const cx = el.getBoundingClientRect().left - ( parent.getBoundingClientRect().width - el.getBoundingClientRect().width ) / 2;
        //     parent.scrollTo(cx, cy);
        // }, 100);
    };

    const deleteBooking = async () => {
        setBusy(true);
        const deleteRes = await api.AnnullaPrenotazione({ Codice: id, UserId: read("userId") });
        if (deleteRes.result) {
            navigate("/soste-programmate");
        } else {
            // console.log(deleteRes.message);
        }
        batchUpdate({
            selectedDate: null,
            selectedSlot: null,
        });
        setBusy(false);
    };

    const back = () => {
        navigate(-1);
    };

    const changeZoom = (factor = 1) => {
        if ((factor < 0 && zoom <= 1) || (factor > 0 && zoom >= 5)) return;

        setZoom(zoom + factor);
        setTimeout(() => {
            centerParkingSlot();
        }, 1);
    };

    const toggleBottomSleeve = () => {
        setBottomSleeveOpen(!bottomSleeveOpen);
    };

    return {
        deleteBooking,
        posto,
        data,
        oraInizio,
        oraFine,
        busy,
        back,
        mapImage,
        mapOverviewImage,
        zoom,
        changeZoom,
        bottomSleeveOpen,
        toggleBottomSleeve,
        mapLegend,
    };
};
