export const isLocal = () => {
  return window.location.host.indexOf("local") != -1;
};
export const isColl = () => {
  return window.location.host.indexOf("myparking-col.appcenter") != -1;
};
export const isProd = () => {
  return window.location.host.indexOf("myparking.appcenter") != -1;
};

export const loadDomainCss = (email) => {  
  const cssId = "adhoc-css";
  
  const s = email.indexOf("@");
  const e = email.lastIndexOf(".");
  const domain = email.substr(s+1, e).replace(".", "_");

  if (document.getElementById(cssId)) {
    const linkNode = document.getElementById(cssId);
    // stop here if we're about to load the same css over and over again
    if(linkNode.getAttribute("href").indexOf(domain) > -1) return;
    
    linkNode.remove()
  }
  
  const head  = document.getElementsByTagName('head')[0];
  const link  = document.createElement('link');
  link.id   = cssId;
  link.rel  = 'stylesheet';
  link.type = 'text/css';
  link.href = `https://preview.monforte.it/myparking/${domain}.css`;
  link.media = 'all';
  head.appendChild(link);  
}