import React from "react";
import macchina from "../../assets/imgs/macchina.svg";
import iconMap from "../../assets/imgs/icon-map.svg";
import BottomNavigation from "../../components/BottomNavigation";
import TitleWithBack from "../../components/TitleWithBack";
import Button from "../../components/Button";
import { useDettaglioPrenotazionePage } from "./useDettaglioPrenotazionePage";
import { Link } from "react-router-dom";
import "./style.css";

export const DettaglioPrenotazione = () => {
    const { back, data, posto, oraInizio, oraFine, deleteBooking, busy, goToBookingDetailMap } = useDettaglioPrenotazionePage();

    return (
        <>
            <div id="booking-details-page" className="booking-detail-page">
                <div className="box--3">
                    <div className="divider--5"></div>

                    <TitleWithBack title="dettaglio prenotazione" onClick={back} />

                    <div className="divider--3"></div>

                    <div className="booking-card rounded bg--white shadow text--center text--distracting padding--2">
                        <div className="divider--3"></div>
                        <p className="text--bold">{data}</p>
                        <div className="divider--1"></div>
                        <div className="bg--xxLightGray rounded display--ib padding--1">
                            <p className="text--semibold">Posto</p>
                            <p className="text--bold fg--primary">{posto}</p>
                        </div>
                        <div className="divider--3"></div>
                        <img className="icon-macchina vibrate-1" src={macchina} alt="" />
                        <div className="terreno-macchina vibrate-2 fade-in"></div>
                        <div className="divider--1"></div>
                        <p className="text--bold">
                            {" "}
                            <span className="fg--secondary">{oraInizio}</span> - <span className="fg--primary">{oraFine}</span>{" "}
                        </p>
                        <div className="divider--3"></div>

                        <div className="button-icon muted flex-cnt align--center" onClick={goToBookingDetailMap}>
                            <div className="">GUARDA LA MAPPA</div>
                            <div className="">
                                <img src={iconMap} alt="" />
                            </div>
                        </div>

                        <div className="divider--2"></div>
                    </div>

                    <div className="divider--10"></div>
                    <div className="divider--2"></div>

                    <Button onClick={deleteBooking} text="cancella prenotazione" isLoading={busy} />

                    <div className="divider--8"></div>
                </div>
            </div>

            <BottomNavigation active="soste" />
        </>
    );
};
