import { useBookings } from "../../hooks/useBookings";
import { useDomainCss } from "../../hooks/useDomainCss";

export const useSosteEffettuatePage = () => {
  const { userBookings, ready } = useBookings(false);
  const {} = useDomainCss();

  return {
    userBookings,
    ready,
  };
};
