import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService as api } from "../../services/ApiService";

export const useSignupPage = () => {
    const [firstName, setName] = useState("");
    const [lastName, setSurname] = useState("");
    const [other, setOther] = useState("");
    const [plate, setPlate] = useState("");
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [password, setPassword] = useState("");
    const [phoneNumber, setPhone] = useState("");
    const [isBusy, setIsBusy] = useState(false);

    const navigate = useNavigate();

    const signup = async (evt) => {
        setErrorMessage("");
        setIsBusy(true);

        if (!plate.length || !email.length || !firstName.length || !lastName.length) {
            setErrorMessage("Assicurati di aver compilato tutti i campi obbligatori");
            setIsBusy(false);
            return;
        }

        evt.preventDefault();
        const payload = {
            FirstName: firstName,
            LastName: lastName,
            PhoneNumber: phoneNumber,
            Email: email,
            Password: password,
            ListaTarghe: plate.toUpperCase(),
        };
        const newUserRes = await api.RegisterNewUser(payload);
        if (!newUserRes.result) {
            setErrorMessage(newUserRes.message);
            setTimeout(() => {
                setErrorMessage("");
            }, 3000);
        } else {
            navigate("/email-confirm");
        }
        setIsBusy(false);
    };

    return {
        signup,
        firstName,
        setName,
        lastName,
        setSurname,
        other,
        setOther,
        plate,
        setPlate,
        email,
        setEmail,
        password,
        setPassword,
        phoneNumber,
        setPhone,
        isBusy,
        errorMessage,
    };
};
