import './style.css';

const Button = ({ classList = "", text = "", isLoading = false, disabled = false, onClick = () => {} }) => {
    return (
        <div className="text--center">
            <button onClick={onClick} disabled={disabled} type="submit" className={`button ${isLoading ? "loading" : ""} ${classList}`}>
                <div className="btn-txt-container">{text}</div>
            </button>
            <div className="lds-circle">
                <div></div>
            </div>
        </div>
    );
};

export default Button;
