import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService as api } from "../../services/ApiService";
import { useSession } from "../../hooks/useSession";

import { loadDomainCss } from "../../services/env";

export const useLoginPage = () => {
  const navigate = useNavigate();
  const { batchUpdate } = useSession();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [busy, setBusy] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [rememberPassword, setRememberPassword] = useState(false);

  
  const onEmailChange = (email) => {
    loadDomainCss(email);
    setEmail(email)
  }

  const login = async (evt) => {
    let toastMessage = "";
    setErrorMessage(toastMessage);
    setBusy(true);
    evt.preventDefault();

    const loginResponse = await api.Login({
      Username: email,
      Password: password,
    });
    if (loginResponse.result) {
      const { firstName, lastName, phoneNumber, userId, targhe } =
        loginResponse;
      batchUpdate({ firstName, lastName, phoneNumber, email, userId, targhe });
      setBusy(false);

      

      navigate("/prenota");
    } else {
      toastMessage = loginResponse.message || "Email o password errata";
      setErrorMessage(toastMessage);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }

    setBusy(false);
  };

  return {
    login,
    email,
    setEmail,
    password,
    setPassword,
    rememberPassword,
    setRememberPassword,
    busy,
    errorMessage,
    onEmailChange
  };
};
