import React from "react";
import MainHeader from "../../components/MainHeader";
import MainFooter from "../../components/MainFooter";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import Toast from "../../components/Toast";
import { useEmailConfirmPage } from "./useEmailConfirmPage";
import InputPassword from "../../components/InputPassword";

export const EmailConfirm = () => {
    const { confirmText } = useEmailConfirmPage();

    return (
        <>
            <div id="forgot-page">
                <div className="box--3">
                    <MainHeader backEnabled={true} />

                    <h1>Conferma la tua email</h1>
                    <div className="divider--1"></div>

                    <p className="align--center--text">
                        {confirmText}
                    </p>

                    <div className="divider--4"></div>

                    <br />
                    <Link to={"/login"}>
                    <Button text="torna al login" />
                    </Link>
                    <br />
                    <br />

                    <MainFooter />
                    <div className="divider--6"></div>
                </div>
            </div>

        </>
    );
};
