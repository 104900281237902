import { useState } from "react";
import { ApiService as api } from "../../services/ApiService";
import { useSession } from "../../hooks/useSession";
import { useDomainCss } from "../../hooks/useDomainCss";

export const useAccountPage = () => {
  const { batchUpdate, read } = useSession();
  const [firstName, setName] = useState(read("firstName") || "");
  const [lastName, setSurname] = useState(read("lastName") || "");
  const [phoneNumber, setPhone] = useState(read("phoneNumber") || "");
  const [busy, setBusy] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const {} = useDomainCss();

  const updateProfile = async (evt) => {
    evt.preventDefault();
    let toastMessage = "Profilo aggiornato con successo.";
    setErrorMessage(toastMessage);
    setBusy(true);

    const updateResponse = await api.ModificaUtente({
      Id: read("userId"),
      FirstName: firstName,
      LastName: lastName,
      PhoneNumber: phoneNumber,
    });
    batchUpdate({ firstName, lastName, phoneNumber });

    if (!updateResponse.result) {
      toastMessage = updateResponse.message || "Modifiche non salvate. Riprova";
    }
    setErrorMessage(toastMessage);
    setTimeout(() => {
      setErrorMessage("");
    }, 3000);

    setBusy(false);
  };

  const logout = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("phoneNumber");
    localStorage.removeItem("email");
    localStorage.removeItem("targhe");
    localStorage.removeItem("userBookings");
    window.location.reload();
  };

  return {
    updateProfile,
    firstName,
    setName,
    lastName,
    setSurname,
    phoneNumber,
    setPhone,
    busy,
    errorMessage,
    logout,
  };
};
