import { useEffect, useState } from "react";
import { ApiService as api } from "../../services/ApiService";

export const useEmailConfirmPage = () => {
    const [confirmText, setConfirmText] = useState("Attendi...");

    useEffect(() => {
        checkEmail();
    }, []);

    const checkEmail = async () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const userId = params["userId"];
        const code = params["code"];

        if (!Object.keys(params).length) {
            setConfirmText("Ti abbiamo mandato un messaggio di attivazione all'indirizzo che hai fornito, clicca sul link per attivare il tuo utente.");
            return;
        }

        try {
            const loginResponse = await api.EmailConfirm({
                userId,
                code,
            });
            if (loginResponse.status == "OK") {
                setConfirmText("Grazie, il tuo account email è stato confermato.");
            } else {
                setConfirmText("Il codice di conferma non è valido.");
            }
        } catch (error) {
            setConfirmText("Il codice di conferma non è valido.");
        }
    };

    return {
        confirmText,
    };
};
