import "./style.css"

const Toast = ({ open = false, children = "" }) => {
  return (
    <div className={`${open ? "open" : ""} component-toast__backdrop`}>
      <div className={`${open ? "open" : "close"} component-toast`}>
        {children}
      </div>
    </div>
  );
};

export default Toast;