import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { ApiService as api } from "../../services/ApiService";
import { useSession } from "../../hooks/useSession";

export const useConfermaPrenotazionePage = () => {
    const navigate = useNavigate();
    const { read, batchUpdate } = useSession();
    const { state } = useLocation();
    const { slotFrom, slotTo, date } = state;
    const [booked, setBooked] = useState(false);
    const [posto, setPosto] = useState("");
    const [busy, setBusy] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const back = () => {
        navigate(-1);
    };

    const bookNow = async () => {
        if (busy) return;

        let toastMessage = "";
        setErrorMessage(toastMessage);
        setBusy(true);
        const _date = moment(date);
        try {
            const bookRes = await api.Prenotazione({
                UserId: read("userId"),
                ClassePostoAuto: "1",
                DataPrenotazione: `${_date.format("YYYY-MM-DD")} ${slotFrom.toString().padStart(5, "0")}:00`,
                DataFinePrenotazione: `${_date.format("YYYY-MM-DD")} ${slotTo.toString().padStart(5, "0")}:00`,
            });
            if (bookRes.result) {
                setPosto(bookRes.postoAutoAssegnato);
                setBooked(true);
                batchUpdate({
                    selectedDate: null,
                    selectedSlot: null,
                    postoAutoAssegnato: bookRes.postoAutoAssegnato,
                });
            } else {
                toastMessage = bookRes.message || "Prenotazione non riuscita. Riprova";
                setErrorMessage(toastMessage);
            }
            setBusy(false);
        } catch (error) {
            setErrorMessage("Prenotazione non riuscita. Riprova");
        } finally {
            setBusy(false);
        }
    };

    return {
        bookNow,
        back,
        slotFrom,
        slotTo,
        date,
        booked,
        posto,
        busy,
        errorMessage,
    };
};
