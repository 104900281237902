import React from "react";
import MainHeader from "../../components/MainHeader";
import MainFooter from "../../components/MainFooter";
import Button from "../../components/Button";
import Toast from "../../components/Toast";
import { useForgotPage } from "./useForgotPage";
import InputPassword from "../../components/InputPassword";
import { Link } from "react-router-dom";

export const Forgot = () => {
    const { sent, sendRecoverEmail, changePassword, email, setEmail, password, setPassword, busy, errorMessage } = useForgotPage();

    return (
        <>
            <div id="forgot-page">
                <div className="box--3">
                    <MainHeader backEnabled={true} />

                    <h1>Recupera la password</h1>
                    <div className="divider--2"></div>

                    {/* @todo: dopo il sent deve solo mostrare testo "controlla la tua email...torna al login" */}
                    {!sent && (
                        <>
                            <p className="align--center--text">
                                Hai dimenticato la password? <br /> Inserisci la mail che hai usato per creane una nuova.
                            </p>

                            <div className="divider--4"></div>
                            <form onSubmit={!sent ? sendRecoverEmail : changePassword}>
                                <div className="form-input-group">
                                    <label htmlFor="">E-mail</label>
                                    <input type="email" autoComplete="new-password" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>

                                {/* {sent && (
                                <>
                                    <div className="divider--3"></div>
                                    <div className="form-input-group">
                                        <label htmlFor="">Password</label>
                                        <InputPassword value={password} onChange={(value) => setPassword(value)} />
                                    </div>
                                </>
                            )} */}
                                <div className="divider--4"></div>
                                <Button isLoading={busy} text={!sent ? "Recupera" : "Salva"} />

                                <div className="divider--8"></div>

                                <hr />
                                <div className="divider--4"></div>
                            </form>
                        </>
                    )}

                    {sent && (
                        <>
                            <p className="align--center--text">Ti abbiamo mandato un messaggio di recupero password all'indirizzo che hai fornito, clicca sul link per completare la procedura.</p>
                            <div className="divider--4"></div>
                            <Link to={"/login"}>
                                <Button text="torna al login" />
                            </Link>
                        </>
                    )}

                    <MainFooter />
                    <div className="divider--6"></div>
                </div>
            </div>

            <Toast open={errorMessage.length}>{errorMessage}</Toast>
        </>
    );
};
