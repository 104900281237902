import React from "react";
import MainHeader from "../../components/MainHeader";
import MainFooter from "../../components/MainFooter";
import Button from "../../components/Button";
import Toast from "../../components/Toast";
import { useSignupPage } from "./useSignupPage";
import InputPassword from "../../components/InputPassword";

export const Signup = () => {
  const {
    signup,
    firstName,
    setName,
    lastName,
    setSurname,
    other,
    setOther,
    plate,
    setPlate,
    email,
    setEmail,
    password,
    setPassword,
    phoneNumber,
    setPhone,
    isBusy,
    errorMessage,
  } = useSignupPage();

  return (
    <>
      <div id="signup-page">
        <div className="box--3">
          <MainHeader backEnabled={true} />

          <h1>Entra in MyParking</h1>
          <div className="divider--1"></div>
          <p className="align--center--text">
            Compila i campi con i dati richiesti e registrati anche tu sull’app.
          </p>

          <div className="divider--4"></div>

          <form onSubmit={signup}>
            <div className="form-input-group">
              <label htmlFor="">Nome</label>
              <input
                type="text"
                required
                autoComplete="new-password"
                value={firstName}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="divider--3"></div>
            <div className="form-input-group">
              <label htmlFor="">Cognome</label>
              <input
                type="text"
                required
                autoComplete="new-password"
                value={lastName}
                onChange={(e) => setSurname(e.target.value)}
              />
            </div>
            <div className="divider--3"></div>
            <div className="form-input-group">
              <label htmlFor="">Altro dato</label>
              <input
                type="text"
                autoComplete="new-password"
                value={other}
                onChange={(e) => setOther(e.target.value)}
              />
            </div>
            <div className="divider--3"></div>
            <div className="form-input-group div-targa">
              <label htmlFor="">Targa</label>
              <input
                required
                type="text"
                autoComplete="new-password"
                value={plate}
                onChange={(e) => setPlate(e.target.value)}
              />
            </div>
            <div className="divider--3"></div>
            <div className="form-input-group">
              <label htmlFor="">E-mail</label>
              <input
                type="email"
                required
                autoComplete="new-password"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="divider--3"></div>
            <div className="form-input-group">
              <label htmlFor="">Password</label>
              <InputPassword
                value={password}
                onChange={(value) => setPassword(value)}
              />
            </div>
            <div className="divider--3"></div>
            <div className="form-input-group">
              <label htmlFor="">Numero cellulare</label>
              <input
                type="text"
                autoComplete="new-password"
                value={phoneNumber}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            <div className="divider--4"></div>

            <Button text="registrati" isLoading={isBusy} />
          </form>

          <div className="divider--8"></div>

          <hr />
          <div className="divider--4"></div>
          <MainFooter />
          <div className="divider--10"></div>
        </div>
      </div>

      <Toast open={errorMessage.length}>{errorMessage}</Toast>
    </>
  );
};
