import React from "react";
import BottomNavigation from "../../components/BottomNavigation";
import TitleWithBack from "../../components/TitleWithBack";
import Button from "../../components/Button";
import { useDettaglioPrenotazioneMappaPage } from "./useDettaglioPrenotazioneMappaPage";
import { Link } from "react-router-dom";
import iconGeneralMap from "../../assets/imgs/icon-general-map.svg";
import tmpMap from "../../assets/imgs/mappa-generale.svg";
import iconPlus from "../../assets/imgs/icon-plus.svg";
import iconMinus from "../../assets/imgs/icon-minus.svg";
import iconClose from "../../assets/imgs/icon-close.png";
import "./style.css";

export const DettaglioPrenotazioneMappa = () => {
    const { back, mapImage, mapOverviewImage, zoom, changeZoom, toggleBottomSleeve, bottomSleeveOpen, mapLegend } = useDettaglioPrenotazioneMappaPage();

    return (
        <>
            <div id="booking-details-page" className={`booking-detail-page map ${bottomSleeveOpen ? "open" : ""}`}>
                <div id="map-general-overlay"></div>
                <div id="map-general-content" className="flex-cnt vertical">
                    <div className="flex-cnt">
                        <h2 className="flex-col--1 padding--2 text--left">POSIZIONE SULLA MAPPA</h2>
                        <div onClick={toggleBottomSleeve} className="padding--2">
                            <img style={{ width: "20px" }} src={iconClose} alt="" />
                        </div>
                    </div>
                    <div className="flex-col--1 text--center">
                        {/* <img style={{width:"80%"}} src={tmpMap} /> */}
                        <div id="map-overview-cnt" dangerouslySetInnerHTML={{ __html: mapOverviewImage }}></div>
                    </div>
                </div>

                <div className="flex-cnt vertical full-height">
                    <div className="">
                        <div className="box--3">
                            <div className="divider--5"></div>
                            <TitleWithBack title="dettaglio<br />prenotazione" onClick={back} />
                            <div className="divider--1"></div>
                        </div>
                        {mapLegend && (
                            <div class="text--center">
                                <div id="map-legend">{mapLegend}</div>
                            </div>
                        )}
                    </div>
                    <div id="map-cnt" className="flex-col--1">
                        <div id="map-content" dangerouslySetInnerHTML={{ __html: mapImage }} style={{ width: `${100 * zoom}%`, marginTop: `${zoom > 3 ? "500px" : ""}` }}></div>
                    </div>
                    <div id="map-buttons">
                        <div id="map-buttons-cnt">
                            <div onClick={toggleBottomSleeve} className="map-button map-button align--center flex-cnt">
                                <img src={iconGeneralMap} alt="" />
                            </div>
                            <div
                                onClick={() => {
                                    changeZoom(1);
                                }}
                                className="map-button map-button align--center flex-cnt">
                                <img src={iconPlus} alt="" />
                            </div>
                            <div
                                onClick={() => {
                                    changeZoom(-1);
                                }}
                                className="map-button map-button align--center flex-cnt">
                                <img src={iconMinus} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <BottomNavigation active="soste" />
        </>
    );
};
