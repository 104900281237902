import { Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSession } from "./hooks/useSession";

import { Splash } from "./pages/splash";
import { Login } from "./pages/login";
import { Forgot } from "./pages/forgot";
import { Signup } from "./pages/signup";
import { Account } from "./pages/account";
import { Prenota } from "./pages/prenota";
import { SosteProgrammate } from "./pages/soste-programmate";
import { SosteEffettuate } from "./pages/soste-effettuate";
import { ConfermaPrenotazione } from "./pages/conferma-prenotazione";
import { DettaglioPrenotazione } from "./pages/dettaglio-prenotazione";
import { PasswordConfirm } from "./pages/password-confirm";
import { EmailConfirm } from "./pages/email-confirm";
import "./App.css";
import { DettaglioPrenotazioneMappa } from "./pages/dettaglio-prenotazione-mappa";

const ProtectedRoute = ({
  children,
  canNavigateIf,
  defaultRoute = "/login",
}) => {
  return canNavigateIf() ? (
    children
  ) : (
    <Navigate replace={true} to={defaultRoute} />
  );
};

const App = () => {
  const { isLoggedIn } = useSession();

  return (
    <div id="app" className="App">
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Splash />} />
            <Route
              path="/login"
              element={
                <ProtectedRoute
                  canNavigateIf={() => {
                    return !isLoggedIn();
                  }}
                  defaultRoute="/prenota"
                >
                  <Login />
                </ProtectedRoute>
              }
            />
            <Route
              path="/forgot"
              element={
                <ProtectedRoute
                  canNavigateIf={() => {
                    return !isLoggedIn();
                  }}
                  defaultRoute="/prenota"
                >
                  <Forgot />
                </ProtectedRoute>
              }
            />
            <Route
              path="/password-confirm"
              element={
                <ProtectedRoute
                  canNavigateIf={() => {
                    return !isLoggedIn();
                  }}
                  defaultRoute="/prenota"
                >
                  <PasswordConfirm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/signup"
              element={
                <ProtectedRoute
                  canNavigateIf={() => {
                    // return !isLoggedIn();
                    return false;
                  }}
                  defaultRoute="/prenota"
                >
                  <Signup />
                </ProtectedRoute>
              }
            />
            <Route
              path="/email-confirm"
              element={
                <ProtectedRoute
                  canNavigateIf={() => {
                    return !isLoggedIn();
                  }}
                  defaultRoute="/email-confirm"
                >
                  <EmailConfirm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/account"
              element={
                <ProtectedRoute canNavigateIf={isLoggedIn}>
                  <Account />
                </ProtectedRoute>
              }
            />
            <Route
              path="/prenota"
              element={
                <ProtectedRoute canNavigateIf={isLoggedIn}>
                  <Prenota />
                </ProtectedRoute>
              }
            />
            <Route
              path="/soste-programmate"
              element={
                <ProtectedRoute canNavigateIf={isLoggedIn}>
                  <SosteProgrammate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/soste-effettuate"
              element={
                <ProtectedRoute canNavigateIf={isLoggedIn}>
                  <SosteEffettuate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/conferma-prenotazione"
              element={
                <ProtectedRoute canNavigateIf={isLoggedIn}>
                  <ConfermaPrenotazione />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dettaglio-prenotazione"
              element={
                <ProtectedRoute canNavigateIf={isLoggedIn}>
                  <DettaglioPrenotazione />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dettaglio-prenotazione-mappa"
              element={
                <ProtectedRoute canNavigateIf={isLoggedIn}>
                  <DettaglioPrenotazioneMappa />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </div>
  );
};

export default App;
