import React from "react";
import MainHeader from "../../components/MainHeader";
import MainFooter from "../../components/MainFooter";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import Toast from "../../components/Toast";
import { usePasswordConfirmPage } from "./usePasswordConfirmPage";
import InputPassword from "../../components/InputPassword";

export const PasswordConfirm = () => {
    const { confirmText, changePassword, password, setPassword, busy, sent, errorMessage } = usePasswordConfirmPage();

    return (
        <>
            <div id="forgot-page">
                <div className="box--3">
                    <MainHeader backEnabled={true} />

                    <h1>Reimposta la Password</h1>
                    <div className="divider--2"></div>

                    {!sent && (
                        <form onSubmit={changePassword}>
                            <p className="align--center--text">Inserisci la tua nuova password.</p>
                            <div className="divider--4"></div>

                            <div className="form-input-group">
                                <label htmlFor="">Password</label>
                                <InputPassword value={password} onChange={(value) => setPassword(value)} />
                            </div>

                            <div className="divider--4"></div>
                            <Button isLoading={busy} text={"Invia"} />

                            <div className="divider--8"></div>

                            <hr />
                            <div className="divider--4"></div>
                        </form>
                    )}

                    <br />

                    {sent && (
                        <>
                            <p className="align--center--text">Password cambiata con successo.</p>
                            <div className="divider--4"></div>
                            <Link to={"/login"}>
                                <Button text="torna al login" />
                            </Link>
                            <br />
                            <br />
                        </>
                    )}

                    <MainFooter />
                    <div className="divider--6"></div>
                </div>
            </div>

            <Toast open={errorMessage.length}>{errorMessage}</Toast>
        </>
    );
};
