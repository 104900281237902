import { SessionService as session } from "../services/SessionService";
import { useState } from "react";
import { isLocal } from "../services/env";

export const useSession = () => {
  const [r, ur] = useState(null);

  const update = (key, value) => {
    session.set(key, value);
    if (!["selectedDate", "selectedSlot"].includes(key)) {
      localStorage.setItem(key, value);
    }
    ur(new Date().getTime());
    if (isLocal()) console.log(read());
  };

  const batchUpdate = (object) => {
    session.merge(object);
    Object.keys(object).forEach((k) => {
      if (null == k) {
        localStorage.removeItem(k);
      } else {
        if (!["selectedDate", "selectedSlot"].includes(k)) {
          localStorage.setItem(k, object[k]);
        }
      }
    });

    ur(new Date().getTime());
    if (isLocal()) console.log(read());
  };

  const read = (key) => {
    return session.get(key) || localStorage.getItem(key) || null;
  };

  const isLoggedIn = () => {
    return read("userId");
  };

  // @remove?
  // const persist = (object) => {
  //   let currentStorage = {};
  //   if (localStorage.getItem("festore")) {
  //     currentStorage = JSON.parse(localStorage.getItem("festore"));
  //   }
  //   localStorage.setItem(
  //     "festore",
  //     JSON.stringify({ ...currentStorage, ...object })
  //   );
  // };

  // const readFromStore = (key) => {
  //   const currentStorage = localStorage.getItem("festore");
  //   if (currentStorage) {
  //     return JSON.parse(currentStorage)[key];
  //   }
  //   return null;
  // };

  return {
    update,
    batchUpdate,
    read,
    isLoggedIn,
  };
};
