import React from "react";
import { Link } from "react-router-dom";
import MainHeader from "../../components/MainHeader";
import Button from "../../components/Button";
import InputPassword from "../../components/InputPassword";
import Toast from "../../components/Toast";
import sfondoMacchina from "../../assets/imgs/macchine-in-fila.svg";
import { useLoginPage } from "./useLoginPage";
import "./style.css";

export const Login = () => {
    const { login, email, onEmailChange, setEmail, password, setPassword, rememberPassword, setRememberPassword, busy, errorMessage, passwordVisible, setPasswordVisible } = useLoginPage();

    return (
        <>
            <div id="login-page">
                <div className="box--3">
                    <MainHeader />

                    <h1>Ciao!</h1>
                    <div className="divider--1"></div>
                    <p className="align--center--text">Inserisci le tue credenziali per e inizia subito a utilizzare l’app MyParking.</p>

                    <div className="divider--3"></div>

                    <form onSubmit={login}>
                        <div className="form-input-group">
                            <label htmlFor="">E-mail</label>
                            <input required type="email" autoComplete="new-password" value={email} onBlur={(e) => onEmailChange(e.target.value)} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="divider--3"></div>
                        <div className="form-input-group">
                            <label htmlFor="">Password</label>

                            <InputPassword rememberPassword={rememberPassword} value={password} onChange={(value) => setPassword(value)} />
                        </div>

                        <div className="divider--5"></div>

                        <div className="flex-cnt">
                            <div className="flex-col--1">
                                <label className="form-checkbox-group">
                                    Ricorda password
                                    <input
                                        type="checkbox"
                                        onChange={(e) => {
                                            setRememberPassword(e.target.checked);
                                        }}
                                        checked={rememberPassword}
                                    />
                                    <span className="form-checkbox-group__checkmark"></span>
                                </label>
                            </div>
                            <div className="flex-col--1">
                                <Link to="/forgot">
                                    <p className="fg--primary text--bold text--right">Recupera dati</p>
                                </Link>
                            </div>
                        </div>

                        <div className="divider--3"></div>

                        <Button isLoading={busy} text="Entra" />

                        {/* <div className="divider--4"></div>

                        <p>
                            Non hai ancora un account? Crea un{" "}
                            <Link to="/signup">
                                <span className="fg--primary text--bold">nuovo account</span>
                            </Link>
                        </p> */}
                    </form>
                </div>

                <Toast open={errorMessage.length}>{errorMessage}</Toast>

                <div className="macchine-compass">
                    <img className="macchine-backg slide-in-left" src={sfondoMacchina} alt="" />
                </div>
            </div>
        </>
    );
};
