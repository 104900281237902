import BottomNavigation from "../../components/BottomNavigation";
import BookingsList from "../../components/BookingsList";
import { useSosteEffettuatePage } from "./useSosteEffettuatePage";
import "./style.css";

export const SosteEffettuate = () => {
  const { userBookings, ready } = useSosteEffettuatePage();

  return (
    <>
      <div id="soste-effettuate-page">
        <BookingsList
          title="soste effettuate"
          classList="elenco_soste-effet"
          userBookings={userBookings}
          ready={ready}
        />
      </div>

      <div className="divider--5"></div>

      <BottomNavigation active="soste" />
    </>
  );
};
