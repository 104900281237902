import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService as api } from "../../services/ApiService";

export const useForgotPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [sent, setSent] = useState(false);
    const [busy, setBusy] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [token, setToken] = useState("");
    const [password, setPassword] = useState("");

    const sendRecoverEmail = async (evt) => {
        let toastMessage = "";        
        setErrorMessage(toastMessage);
        setBusy(true);
        evt.preventDefault();

        const loginResponse = await api.RecoverPWD({
            Username: email
        });
        if(loginResponse.result) {
            setToken(loginResponse.token);
            setBusy(false);
            setSent(true);
        }
        else {
            toastMessage = "Utente non trovato"
            setErrorMessage(toastMessage);
        }

        setBusy(false);
    };

    const changePassword = async (evt) => {
        let toastMessage = "";        
        setErrorMessage(toastMessage);
        setBusy(true);
        evt.preventDefault();

        const changePwdResponse = await api.ChangePWD({
            Username: email,
            Token: token,
            NewPwd: password,
        });
        if(changePwdResponse.result) {
            setBusy(false);
        }
        else {
            toastMessage = `Salvataggio non riuscito: ${changePwdResponse.message}`;
            setErrorMessage(toastMessage);
        }

        setBusy(false);
        navigate("/login");
    }

    return { 
        sent, 
        sendRecoverEmail, 
        changePassword, 
        email, 
        setEmail, 
        password, 
        setPassword, 
        busy, 
        errorMessage 
    };
}