import React from "react";
import logo from "../../assets/imgs/logo_monforte.png";
import { useSplashPage } from "./useSplashPage"
import './splash.css';

export const Splash = () => {
    const {} = useSplashPage();    

    return (
        <div id="splashscreen" className="page">
            <div id="splashimage">
                <img src={logo} />
            </div>            
        </div>
    )
}
