import { useState } from "react";
import showPasswordIcon from "../../assets/imgs/show-pwd.svg";
import hidePasswordIcon from "../../assets/imgs/hide-pwd.svg";
import "./style.css";

const InputPassword = ({
  classList = "",
  rememberPassword = false,
  value = "",
  onChange = () => {},
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <div className={`input-password__container ${classList}`}>
      <input
        className="input-password__input"
        required
        type={passwordVisible ? "text" : "password"}
        autoComplete={rememberPassword ? "on" : "new-password"}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      {!passwordVisible ? (
        <img
          className="input-password__icon"
          src={showPasswordIcon}
          onClick={() => {
            setPasswordVisible(!passwordVisible);
          }}
          alt=""
        />
      ) : (
        <img
          className="input-password__icon"
          src={hidePasswordIcon}
          onClick={() => {
            setPasswordVisible(!passwordVisible);
          }}
          alt=""
        />
      )}
    </div>
  );
};

export default InputPassword;
