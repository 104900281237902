import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useSplashPage = () => {
    const navigate = useNavigate();
    
    useEffect(() => {
        setTimeout(() => {
            navigate("/login");
        }, 2000);
    }, [])

    return {};
}