import chevron from "../../assets/imgs/chevron-left.svg";
import "./style.css";

const TitleWithBack = ({ title = "", onClick = () => {} }) => {
  return (
    <div className="flex-cnt align--center--v">
      <img src={chevron} className="back-icon dark" alt="" onClick={onClick} />
      <h2 className="flex-col--1" dangerouslySetInnerHTML={{__html:title}}></h2>
    </div>
  );
};

export default TitleWithBack;
