import { useNavigate } from "react-router-dom";
import chevron from "../../assets/imgs/chevron-left.svg";
import "./style.css";

const MainHeader = ({ backEnabled = false }) => {
    const navigate = useNavigate();

    return (
        <>
            <div className="divider--3"></div>

            {backEnabled && (
                <img
                    src={chevron}
                    className="back-icon light-dark"
                    alt=""
                    onClick={() => {
                        navigate(-1);
                    }}
                />
            )}
            <div className="text--center">
                <div className="logo"></div>
            </div>

            <div className="divider--4"></div>
        </>
    );
};

export default MainHeader;
