import { Link } from "react-router-dom";
import utente from "../../assets/imgs/menu-utente.svg";
import cronometro from "../../assets/imgs/menu-cronometro.svg";
import lente from "../../assets/imgs/menu-lente.svg";
import "./style.css";

const BottomNavigation = ({ active = "prenota" }) => {
  return (
    <div id="main-menu" className="z--top">
      <div className="box--3">
        <div id="main-menu__sleeve"></div>
        <div
          id="main-menu__buttons-group"
          className="flex-cnt flex-cnt--anchor-antipodes align--top--v"
        >
          <Link to="/account" className="menu-account">
            <img
              src={utente}
              alt=""
              className={active == "account" ? "active" : ""}
            />
          </Link>
          <Link to="/prenota" className="menu-prenota rotate-center">
            <img
              src={cronometro}
              alt=""
              className={active == "prenota" ? "active" : ""}
            />
          </Link>
          <Link to="/soste-programmate" className="menu-soste">
            <img
              src={lente}
              alt=""
              className={active == "soste" ? "active" : ""}
            />
          </Link>
        </div>
      </div>
      <div id="ombra_menu"></div>
    </div>
  );
};

export default BottomNavigation;
