import "./style.css";

const MainFooter = () => {
    return (
        <footer id="main-footer" className="text--micro text--bold">
            <div className="contenuto-testuale-footer"></div>
        </footer>
    );
};

export default MainFooter;
