import { useNavigate } from "react-router-dom";
import { useBookings } from "../../hooks/useBookings";
import { useDomainCss } from "../../hooks/useDomainCss";

export const useSosteProgrammatePage = () => {
  const navigate = useNavigate();
  const { userBookings, ready } = useBookings(true);
  const {} = useDomainCss();

  const goToBookingDetail = (data) => {
    navigate("/dettaglio-prenotazione", {
      state: data,
    });
  };

  return {
    userBookings,
    goToBookingDetail,
    ready,
  };
};
