import BottomNavigation from "../../components/BottomNavigation";
import BookingsList from "../../components/BookingsList";
import moment from "moment";
import { useSosteProgrammatePage } from "./useSosteProgrammatePage";
import "./style.css";

export const SosteProgrammate = () => {
  const { userBookings, ready, goToBookingDetail } = useSosteProgrammatePage();

  return (
    <>
      <div id="soste-programmate-page">
        <BookingsList
          title="soste programmate"
          classList="elenco_soste-prog"
          userBookings={userBookings}
          ready={ready}
          onItemClick={(itm) => {
            goToBookingDetail({
              id: itm.numeroPrenotazione,
              posto: itm.postoAuto,
              mapRef: itm.mappa, // itm.mappa,
              data: moment(itm.dataInizio).format("DD.MM.YY"),
              oraInizio: moment(itm.dataInizio).format("HH:mm"),
              oraFine: moment(itm.dataFine).format("HH:mm"),
              dataInizio: itm.dataInizio,
              dataFine: itm.dataFine
            });
          }}
        />
      </div>

      <div className="divider--5"></div>

      <BottomNavigation active="soste" />
    </>
  );
};
