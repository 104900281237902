import { Link } from "react-router-dom";
import moment from "moment";
import loaderGif from "../../assets/imgs/loader.gif";
import "./style.css";

const BookingsList = ({
  classList = "",
  title = "",
  userBookings = [],
  ready = false,
  onItemClick = (itm) => {},
}) => {
  return (
    <>
      <div className="bookings-archive-header-cnt z--emphasis">
        <div className="bookings-archive-header">
          <Link to="/soste-effettuate">
            <p>SOSTE EFFETTUATE</p>
          </Link>
          <Link to="/soste-programmate">
            <p>SOSTE PROGRAMMATE</p>
          </Link>
        </div>

        <div className="padding--1">
          <div className="divider--3"></div>

          <h1>{title}</h1>

          <div className="divider--3"></div>

          <div className="flex-cnt fg--primary text--micro text--center">
            <div className="flex-col--1">Posto</div>
            <div className="flex-col--2">Data</div>
            <div className="flex-col--2">Orario</div>
          </div>

          <div className="divider--05"></div>
          <hr className="primary" />
        </div>
      </div>

      <div className="padding--1">
        <div className={`elenco_soste ${classList}`}>
          {!userBookings.length && !ready && (
            <>
              <div className="divider--2"></div>
              <div className="text--center">
                <img id="loader-gif" src={loaderGif} alt="" />
              </div>
            </>
          )}

          {!userBookings.length && ready && (
            <>
              <div className="divider--2"></div>
              <p className="text--center fg--gray">
                Nessuna prenotazione effettuata
              </p>
            </>
          )}
          {userBookings.map((itm, idx) => {
            return (
              <div key={`booking-${idx}`}>
                <div className="divider--1"></div>
                <div
                  className={`bookings-archive-item bg--xxLightGray flex-cnt align--center--v text--center padding--1 ${
                    moment(itm.dataInizio).isSame(moment(), "date")
                      ? "active"
                      : ""
                  } ${itm.stato == "Annullato" ? "disabled" : ""}`}
                  onClick={() => {
                    if (itm.stato == "Annullato") return;
                    onItemClick(itm);
                  }}
                >
                  <div className="flex-col--1">
                    Posto <br />{" "}
                    <span className="fg--primary text--bold text--emphasis-3">
                      {itm.postoAuto}
                    </span>
                  </div>
                  <div className="flex-col--2">
                    <span className="text--bold text--emphasis-3">
                      {moment(itm.dataFine).format("DD.MM.YY")}
                    </span>
                  </div>
                  <div className="flex-col--2">
                    <span className="fg--secondary text--bold text--emphasis-3">
                      {moment(itm.dataInizio).format("HH:mm")}
                    </span>
                    &nbsp; &nbsp;
                    <span className="fg--primary text--bold text--emphasis-3">
                      {moment(itm.dataFine).format("HH:mm")}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default BookingsList;
