import axios from "axios";
import { isLocal, isColl } from "./env";
import { SessionService as session } from "./SessionService";
import {maps} from "../assets/maps.js";

export const ApiService = () => {};

const getBaseUrl = (endpoint) => {
    if (isLocal()) {
        return `/API/${endpoint}`;
    } else if (isColl()) {
        return `https://parking-col.appcenter.it/API/${endpoint}`;
    } else {
        return `https://parking.appcenter.it/API/${endpoint}`;
    }
};

const getAccessToken = () => {
    return session.get("userId") || localStorage.getItem("userId");
};

const response = (endpoint, payload = {}, requireToken = true, method = "post") => {
    let headers = {
        headers: {
            Authorization: `Bearer ${getAccessToken()}`,
        },
    };
    return new Promise(async (res, rej) => {
        try {
            const result = await axios[method](endpoint, { ...payload }, requireToken && headers);
            if (result.status != 200) {
                rej(result.message);
            } else {
                res(result.data);
            }
        } catch (error) {
            rej(error);
        }
    });
};

const mockResponse = () => {
    return new Promise(res => {
        setTimeout(() => {
            console.log("promise ok");
            res({result: "delete ok"});
        }, 1000);
    });
}

/* === SERVICES === */

ApiService.getMaps = (payload) => {
    return new Promise(async (res, rej) => {
        try {
            const result = await response(`${getBaseUrl("DatiMappaSvg")}`, payload, false, "get");
            res({
                ...maps,
                ...result,
            })            
        } catch (error) {
            res(maps)
        }
    });
};

ApiService.CercaUtente = (payload) => {
    return response(`${getBaseUrl("CercaUtente")}`, payload);
};

ApiService.RegisterNewUser = (payload) => {
    return response(`${getBaseUrl("RegisterNewUser")}`, payload, false);
};

ApiService.ListaPrenotazioni = async (payload) => { 
    return new Promise(async (res, rej) => {
        try {            
            res(await response(`${getBaseUrl("ListaPrenotazioni")}`, payload));
            
        } catch (error) {
            // @tmp
            localStorage.removeItem("userId");
            localStorage.removeItem("firstName");
            localStorage.removeItem("lastName");
            localStorage.removeItem("phoneNumber");
            localStorage.removeItem("email");
            localStorage.removeItem("targhe");
            localStorage.removeItem("userBookings");
            window.location.reload();
            rej(error.message || "Sessione scaduta");
        }
    });

};

ApiService.Prenotazione = (payload) => {
    return response(`${getBaseUrl("Prenotazione")}`, payload);
};

ApiService.AnnullaPrenotazione = (payload) => {
    return response(`${getBaseUrl("AnnullaPrenotazione")}`, payload);
    // return mockResponse();
};

ApiService.Login = (payload) => {
    return response(`${getBaseUrl("Login")}`, payload, false);
};

ApiService.ModificaUtente = (payload) => {
    return response(`${getBaseUrl("ModificaUtente")}`, payload);
};

// prima chiamata per farmi arrivare l'email
ApiService.RecoverPWD = (payload) => {
    return response(`${getBaseUrl("RecoverPWD")}`, payload, false);
};

ApiService.RecoverPWDConfirm = (payload) => {
    // return response(`${getBaseUrl("UpdatePasswordSubmit")}`, payload, false);
    return response(`${getBaseUrl("UpdateRecoveredPassword")}`, payload, false);
};

ApiService.EmailConfirm = (payload) => {
    // return response(`${getBaseUrl("ConfirmEmailSubmit")}`, payload, false);
    return response(`${getBaseUrl("EmailConfirm")}`, payload, false);
};

ApiService.ChangePWD = (payload) => {
    return response(`${getBaseUrl("ChangePWD")}`, payload, false);
};

ApiService.GetHourSlots = (payload) => {
    //https://parking.appcenter.it/api/Getslots
    return response(`${getBaseUrl("Getslots")}`, payload, false, "get");
};
